<template>
  <div class="container" id="home-slider">
    <div class="container-fluid banner-bg">
      <div class="row d-flex justify-content-center">
        <div class="col-md-2">
          <router-link to="/home">
            <img
              src="@/assets/site/images/new/logo-1.png"
              class="img-fluid"
              alt=""
            />
          </router-link>
        </div>
        <div class="col-md-6">
          <img
            src="@/assets/site/images/new/banner.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
    <div class="container-fluid px-0 mx-0 my-5">
      <div class="findgft">
        <h1 class="display-1 mb-0" style="font-weight: 400">
          <span>
            <img
              class="giftbox-img mr-2"
              src="@/assets/site/images/gift-box-left.svg"
              alt=""
          /></span>
          find the perfect gift<sup>TM</sup
          ><span>
            <img
              class="giftbox-img ml-4"
              src="@/assets/site/images/gift-box-right.svg"
              alt=""
          /></span>
        </h1>
      </div>
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-6 col-lg-2 mb-5">
          <div
            class="
              category-holder
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <img
              src="@/assets/site/images/new/laptop.png"
              width="150"
              height="150"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
        <div class="col-6 col-lg-2 mb-5">
          <div
            class="
              category-holder
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <img
              src="@/assets/site/images/new/shoes.jpg"
              width="150"
              height="150"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
        <div class="col-6 col-lg-2 mb-5">
          <div
            class="
              category-holder
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <img
              src="@/assets/site/images/new/pendant.jpg"
              width="150"
              height="150"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
        <div class="col-6 col-lg-2 mb-5">
          <div
            class="
              category-holder
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <img
              src="@/assets/site/images/new/smart-watch.webp"
              width="150"
              height="150"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
        <div class="col-6 col-lg-2 mb-5">
          <div
            class="
              category-holder
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <img
              src="@/assets/site/images/new/watch.webp"
              width="150"
              height="150"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid px-0 mx-0 my-5">
      <div class="findgft">
        <h1 class="display-1 mb-0" style="font-weight: 400">
          <!-- <span>
            <img
              class="giftbox-img mr-2"
              src="@/assets/site/images/gift-box-left.svg"
              alt=""
          /></span> -->
          A NEW ERA OF HOW YOU BUY GIFTS HAS ARRIVED!
          <!-- <span>
            <img
              class="giftbox-img ml-4"
              src="@/assets/site/images/gift-box-right.svg"
              alt=""
          /></span> -->
        </h1>
      </div>
    </div>
    <div class="container-fluid px-0 my-5 bg-news2">
      <img
        src="@/assets/site/images/new/group-22.png"
        class="img-fluid pt-3"
        alt=""
      />
    </div>
    <h1 class="display-1 text-center text-red font-weight-bold">
      "Govava is The Google of Gifting"
    </h1>
    <div class="container-fluid px-0 bg-orange2">
      <div class="row d-flex align-items-center">
        <div class="col-md-2"></div>
        <div class="col-md-6">
          <router-link to="/home">
            <img
              src="@/assets/site/images/new/logo.png"
              class="img-fluid text-center"
              alt=""
            />
          </router-link>
          <h3 class="mb-4 py-4">BE THE ROCK STAR OF GIFTING</h3>
          <h3 class="mb-4 pl-7 py-4">FIND THE RIGHT GIFT ALL THE TIME</h3>
          <h3 class="mb-4 pl-10 py-4">SAVE MONEY WHILE DOING IT</h3>
          <div class="testimonial-box mb-4">
            <p>
              “I’ve Never Seen or Experienced Anything Like This Before”; Truly
              Amazing”.
            </p>
            <p>
              M. Gordon. <br />
              Brooklyn, NY 2021
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <img
            src="@/assets/site/images/new/testi.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="container-fluid" style="margin-top: 20px;">
      <div class="row d-flex">
        <div class="col-md-3">
          <div class="bg-blue px-3 py-5">
            <h2 class="text-white font-weight-bold">
              Based on your profile, Govava A.I will keep your Gift history
              which will always help your family Find The Perfect Gift<sup
                >TM</sup
              >
            </h2>
            <img
              src="@/assets/site/images/new/gift-1.jpg"
              class="img-fluid py-5"
              alt=""
            />
          </div>
          <div class="bg-orange py-5 px-3">
            <h2 class="text-white font-weight-bold">ALL THROUGH THE YEARS</h2>
          </div>
        </div>
        <div class="col-md-6">
          <img
            src="@/assets/site/images/new/products.png"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-md-3">
          <div class="bg-blue px-3 py-3">
            <div class="row d-flex align-items-center">
              <svg height="210" width="500" class="line">
                <line
                  x1="0"
                  y1="0"
                  x2="300"
                  y2="100"
                  style="stroke: #05a5e1; stroke-width: 2"
                />
              </svg>
              <svg height="210" width="500" class="line2">
                <line
                  x1="0"
                  y1="200"
                  x2="200"
                  y2="100"
                  style="stroke: #05a5e1; stroke-width: 2"
                />
              </svg>
              <div class="col-md-4">
                <h2 class="text-white font-weight-bold">AI</h2>
              </div>
              <div class="col-md-8 pb-5">
                <img
                  src="@/assets/site/images/new/ai.jpg"
                  class="py-3 ai"
                  alt=""
                />
              </div>
            </div>

            <h2 class="text-white font-weight-bold text-center pt-5">FAMILY</h2>

            <img
              src="@/assets/site/images/new/family.jpg"
              class="img-fluid py-3"
              alt=""
              width="250"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid py-5">
      <div class="row d-flex align-items-center bg-news">
        <div class="col-md-5">
          <img
            src="@/assets/site/images/new/mobile.webp"
            class="img-fluid"
            alt=""
          />
          <span>
            <router-link to="/home">
              <h2 class="text-red font-weight-bold text-center py-3">
                <u>Use our Gift Wizard</u>
              </h2>
            </router-link></span
          >
          <div class="d-flex justify-content-center">
            <router-link to="/home">
              <img
                src="@/assets/site/images/new/wizard.png"
                width="120"
                class="img-fluid"
                alt=""
              />
            </router-link>
          </div>
        </div>
        <div class="col-md-7">
          <h1 class="text-white px-3 py-3 text-center">
            OUR MOBILE APP IS JUST AS AMAZING
          </h1>
          <h3 class="text-warning font-weight-bold px-3 py-3 text-center">
            DOWNLOAD NOW
          </h3>
          <div class="row d-flex align-items-center justify-content-center">
            <div class="col-4">
              <a
                href="https://apps.apple.com/in/app/govava/id1555186188"
                target="_blank"
              >
                <img src="@/assets/site/images/new/app-store.png" alt="" />
              </a>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <img
              src="@/assets/site/images/new/mob-4.jpg"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container py-5">
      <h1 class="text-red text-center font-weight-bold">
        NO MORE SAVING YOUR ITEMS IN THE CART FOR LATER
      </h1>
      <div class="row d-flex justify-content-between align-items-center">
        <div class="col-md-1"></div>
        <div class="col-md-2 text-center font-weight-bold text-dark">
          <a @click="wishListLoginCheck('my_wishlist')">
            <img
              src="@/assets/site/images/new/gift-5.png"
              class="img-fluid"
              alt=""
            />
          </a>
          <h3>My Wishlist</h3>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-4">
          <img
            src="@/assets/site/images/new/gift-6.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-md-2 text-center font-weight-bold text-dark">
          <a @click="wishListLoginCheck('pet_wishlist')">
            <img
              src="@/assets/site/images/new/gift-5.png"
              class="img-fluid"
              alt=""
            />
          </a>
          <h2>Pets Wishlist</h2>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
    <div class="container-fluid px-0 py-5 bg-blue">
      <div class="row d-flex d-flex justify-content-center align-items-center">
        <!-- <div class="col-md-2"></div> -->
        <div class="col-md-3">
          <router-link to="/home">
            <img
              src="@/assets/site/images/new/shop.png"
              class="img-fluid"
              alt=""
            />
          </router-link>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-2 text-center font-weight-bold text-danger">
          <a @click="wishListLoginCheck('friends_wishlist')">
            <img
              src="@/assets/site/images/new/gift-5.png"
              class="img-fluid"
              alt=""
            />
          </a>
          <h2 class="text-red">Friends & Family Wishlist</h2>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-3">
          <router-link to="/home">
            <img
              src="@/assets/site/images/new/shop-now.png"
              class="img-fluid"
              alt=""
            />
          </router-link>
        </div>
      </div>
    </div>
    <div class="container-fluid px-0 mx-0 my-5" id="coupons">
      <div class="bg-blue py-2">
        <h1
          class="display-4 mb-0 text-center font-weight-bold text-white"
          style="font-weight: 400"
        >
          <!-- <span>
            <img
              class="giftbox-img mr-2"
              src="@/assets/site/images/gift-box-left.svg"
              alt=""
          /></span> -->
          COUPONS FOR ITEMS ON SALE ARE INSTANTLY DISPLAYED. <br />
          NO MORE SEARCHING FOR CODES
          <!-- <span>
            <img
              class="giftbox-img ml-4"
              src="@/assets/site/images/gift-box-right.svg"
              alt=""
          /></span> -->
        </h1>
      </div>
      <img
        src="@/assets/site/images/new/bg-coupons.png"
        class="img-fluid"
        alt=""
      />
    </div>
    <div class="container-fluid bg-yellow2">
      <h1 class="text-red font-weight-bold display-1 text-center">Gift Chat</h1>
      <div class="row d-flex justify-content-center">
        <div class="col-md-12">
          <img
            src="@/assets/site/images/new/bg-group-chat.png"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
    </div>
    <h3 class="text-warning font-weight-bold px-3 py-3 text-center">
      DOWNLOAD NOW
    </h3>
    <div class="row d-flex align-items-center justify-content-center">
      <div class="col-2">
        <a
          href="https://apps.apple.com/in/app/govava/id1555186188"
          target="_blank"
        >
          <img src="@/assets/site/images/new/app-store.png" alt="" />
        </a>
      </div>
    </div>
    <div class="container-fluid bg-yellow py-5 px-5">
      <h1 class="text-white text-center font-weight-bold">
        YOUR WISHLIST CAN BE VIEWED BY YOUR FRIENDS AND FAMILY
      </h1>
      <div class="row d-flex justify-content-center align-items-center px-5">
        <div class="col-md-6">
          <img
            src="@/assets/site/images/new/products-2.png"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-md-4">
          <img
            src="@/assets/site/images/new/girl.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="container">
      <h1 class="diplay-1 wishlist-head text-center font-weight-bold">
        WISHLIST
      </h1>
      <img src="@/assets/site/images/new/group.png" class="img-fluid" alt="" />
    </div>
    <div class="container-fluid bg-pet py-5 px-5">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-3">
          <router-link to="/home">
            <img
              src="@/assets/site/images/new/shop-now-2.png"
              class="img-fluid"
              alt=""
            />
          </router-link>
        </div>
        <div class="col-7">
          <h1 class="text-dark text-center font-weight-bold">
            SEARCH GIFTS FOR YOUR PET
          </h1>
        </div>
      </div>
      <div class="row d-flex align-items-center">
        <div class="col-md-4">
          <img
            src="@/assets/site/images/new/pets.png"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-md-4">
          <img
            src="@/assets/site/images/new/cat.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-3">
          <img
            src="@/assets/site/images/new/insta.png"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
      <img
        src="@/assets/site/images/new/pets-icons.png"
        class="img-fluid"
        alt=""
      />
    </div>
    <div class="container-fluid bg-blue pb-5">
      <h1 class="text-center text-white py-3">Reviews</h1>
      <div class="findgft py-4">
        <div
          class="row d-flex d-flex justify-content-center align-items-center"
        >
          <div class="col">
            <img src="@/assets/site/images/new/rate-1.png" alt="" />
            <h3 class="rating">
              S. Patel<br />
              IN
            </h3>
          </div>
          <div class="col">
            <img src="@/assets/site/images/new/rate-2.png" alt="" />
            <h3 class="rating">
              "Simply Amazing<br />
              P. Lee, FL"
            </h3>
          </div>
          <div class="col">
            <img src="@/assets/site/images/new/rate-1.png" alt="" />
            <h3 class="rating">
              “I was blown away”<br />
              Corey, NY
            </h3>
          </div>
          <div class="col">
            <img src="@/assets/site/images/new/rate-2.png" alt="" />
            <h3 class="rating">
              “This is the Future Of Gift Buying”<br />
              M Baldi, NY
            </h3>
          </div>
          <div class="col">
            <img src="@/assets/site/images/new/rate-1.png" alt="" />
            <h3 class="rating">
              D. Kellyman<br />
              FL
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid bg-orange py-5 px-5">
      <img src="@/assets/site/images/new/cards.png" class="img-fluid" alt="" />
    </div>
    <div class="container py-3">
      <div class="row d-flex align-items-center router-link-active">
        <div class="col-md-6 text-center">
          <a class="category-hover" @click="giftCard_search()">
            <h1 class="text-center">SHOP NOW</h1>
            <img
              src="@/assets/site/images/new/gift-card.jpg"
              class="img-fluid text-center"
              alt=""
              width="450"
            />
          </a>
        </div>
        <div class="col-md-6">
          <img
            src="@/assets/site/images/new/girl-2.jpg"
            class="img-fluid"
            alt=""
            style="
              border-radius: 20px;
              box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewHome",
  created() {
    if (localStorage.getItem("landingPage")) {
      window.location.href = "home";
    } else {
      // localStorage.setItem("landingPage", true);
    }
  },
  methods: {
    giftCard_search() {
      this.$store.state.pet_id = null;
      this.$store.state.occasion_id = null;
      this.search_load = true;
      var fetch_url =
        process.env.VUE_APP_URL + "customer/search/keyword_search";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          keyword: "gift card",
          type: "gift_card",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.search_load = false;
          //  this.loader = false;
          var products = data.response;

          const id = Math.random().toString(36).slice(2);
          this.$store.state.keyword = "gift card";
          this.$store.state.products = data.response.item;
          this.$store.state.totalPages = data.response.TotalPages;
          this.$store.state.page = 1;
          this.$router.replace({
            name: "NewSearch",
            params: {
              id,
            },
          });
          // this.search_word = null;
          // this.selected_category = '';
        })
        .catch(function (err) {
          console.log("Fetch Error :-S", err);
        });
    },
    wishListLoginCheck(text) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        // alert("Please log in to continue");
        window.location.href = "login";
      } else {
        if (text == "my_wishlist") {
          window.location.href = "my_occasion";
        } else if (text == "pet_wishlist") {
          window.location.href = "pet_list";
        } else if (text == "friends_wishlist") {
          window.location.href = "friends_list";
        }
      }
    },
  },
};
</script>

<style>
@font-face {
  font-family: "Jokerman";
  src: url("//db.onlinewebfonts.com/t/eec0608ff80827a878d7da11b3b71857.eot");
  src: url("//db.onlinewebfonts.com/t/eec0608ff80827a878d7da11b3b71857.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/eec0608ff80827a878d7da11b3b71857.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/eec0608ff80827a878d7da11b3b71857.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/eec0608ff80827a878d7da11b3b71857.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/eec0608ff80827a878d7da11b3b71857.svg#Jokerman")
      format("svg");
}
@media (max-width: 479px) {
  .logo,
  .img-fluid {
    max-width: 100% !important;
  }
  .category-holder {
    width: 150px !important;
    height: 150px !important;
    border-radius: 50%;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
  }
  .category-holder img {
    width: 60% !important;
  }
  .bg-blue h2 {
    font-size: 1.5em !important;
  }
  #coupons h1 {
    font-size: 18px !important;
  }
}
.category-holder {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
}
.category-holder1 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
}
.category-holder:hover {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.7);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.7);
}
.bg-orange {
  background-color: #d83b01;
}
.bg-orange2 {
  background: linear-gradient(90deg, #d83b01 100%, #ffffff 50%);
}
.bg-yellow {
  background-color: #ffc000;
}
.bg-yellow2 {
  /* background: linear-gradient(90deg, #ffffff 30%, #ffc000 70%); */
  background: linear-gradient(180deg, #ffc000 90%, #ffffff 30%);
}
.bg-pet {
  background: linear-gradient(180deg, #ffc000 50%, #ffffff 50%);
}
.bg-blue {
  background-color: #354655;
}
.bg-orange2 h3 {
  color: white;
  font-weight: bold;
  font-size: 35px;
}
.testimonial-box {
  border: 1px solid black;
  border-radius: 30px;
  padding: 30px;
  width: 80%;
}
.testimonial-box p {
  font-size: 25px;
  color: orange;
  font-weight: bold;
}
.bg-news {
  background: linear-gradient(180deg, #354655 50%, #ffffff 50%);
}
.bg-news2 {
  background: linear-gradient(180deg, #354655 80%, #ffffff 20%);
}
.ai {
  width: 40%;
}
/* #coupons h1 {
  font-size: 35px !important;
} */
.rating {
  color: black;
  text-transform: none !important;
  font-size: 25px !important;
}
/* .header-container {
  display: none !important;
} */
.banner-bg {
  background-color: #354655 !important;
}
.wishlist-head {
  color: #ff0000 !important;
  font-size: 50px !important;
  font-family: "Jokerman" !important;
}
.text-red {
  color: #ff0000 !important;
}
/* .footer-middle {
  display: none;
} */
.text-box2 {
  position: absolute;
  color: #ffffff;
  font-weight: bold !important;
  border: 1px solid black !important;
  /* bottom: -760%; */
  left: 55%;
  padding: 10px;
}
.pl-10 {
  padding-left: 12rem;
}
.pl-7 {
  padding-left: 6rem;
}
/* .modal-close {
  right: 0;
  position: absolute;
  cursor: pointer;
} */
</style>